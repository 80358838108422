<template>
  <v-row class="ma-0">
    <v-col class="d-flex flex-column white pt-2">
      <v-row class="mx-2 mt-5">
        <v-col>
          <s-text-field
            name="name"
            label="Project name"
            type="text"
            filled
            required
            v-model="value.name"
            :error-messages="getErrors('text', $v.value.name, 'Project name')"
            @blur="$v.value.name.$touch()"
          />
          <s-text-field
            name="description"
            label="Description"
            type="text"
            class="mb-n1"
            filled
            required
            v-model="value.description"
          />
          <s-select
            label="Type"
            name="type"
            filled
            required
            :items="types"
            v-model="value.type"
            :error-messages="getErrors('text', $v.value.type, 'Type')"
            @blur="$v.value.type.$touch()"
          />
          <s-btn
            @click="addEndpoints"
            type="button"
            color="primary"
            elevation="0"
          >
            Submit
          </s-btn>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import getErrors from '@/mixins/vuelidateErrors'
import { required } from 'vuelidate/lib/validators'
export default {
  name: 'AppProjectForm',
  mixins: [getErrors],
  props: {
    value: {
      type: Object,
      default: () => ({
        name: '',
        description: '',
        type: '',
      }),
    },
  },
  computed: {
    types() {
      return ['nga-west-1']
    },
  },
  data() {
    return {
      isLoading: false,
    }
  },
  methods: {
    showAddEndpoint() {
      this.$emit('showAppEndpoint')
    },
    addEndpoints() {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      const payload = {
        description: this.value.description,
        name: this.value.name,
        region: this.value.type,
      }
      this.$emit('submit', payload)
    },
  },
  validations() {
    return {
      value: {
        name: { required },
        type: { required },
      },
    }
  },
}
</script>
