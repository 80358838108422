<template>
  <v-row class="ma-0">
    <v-col class="d-flex flex-column white pt-2">
      <v-form ref="form" @submit.prevent="save">
        <v-row class="mx-2 mt-5">
          <v-col>
            <s-text-field
              name="name"
              label="ENV Name"
              type="text"
              filled
              required
              v-model="value.name"
              :error-messages="getErrors('text', $v.value.name, 'ENV Name')"
              @blur="$v.value.name.$touch()"
            />
            <s-text-field
              name="value"
              label="Value"
              type="text"
              class="mb-n1"
              filled
              required
              v-model="value.description"
              :error-messages="getErrors('text', $v.value.description, 'Value')"
              @blur="$v.value.description.$touch()"
            />
            <s-btn type="submit" color="primary" elevation="0"> Add </s-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-col>
  </v-row>
</template>
<script>
import getErrors from '@/mixins/vuelidateErrors'
import { required } from 'vuelidate/lib/validators'
export default {
  name: 'AppEndpointForm',
  mixins: [getErrors],
  props: {
    value: {
      type: Object,
      default: () => ({
        name: '',
        description: '',
      }),
    },
    type: {
      type: String,
      required: true,
    },
  },
  methods: {
    showAddEndpoint() {
      this.$emit('showAppEndpoint')
    },
    save() {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      const payload = {
        name: this.value.name,
        value: this.value.description,
      }
      this.$emit('submit', payload)
      this.$router.go(-1)
    },
  },
  validations() {
    return {
      value: {
        name: { required },
        description: { required },
      },
    }
  },
}
</script>
