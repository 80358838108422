<template>
  <v-row class="ma-0">
    <v-col class="d-flex flex-column pt-12">
      <v-form ref="form" @submit.prevent="save">
        <v-row style="max-width: 500px">
          <v-col>
            <s-text-field
              name="appName"
              label="Name"
              type="text"
              filled
              required
              v-model="value.name"
              :error-messages="
                getErrors('text', $v.value.name, 'App name', '2')
              "
              @blur="$v.value.name.$touch()"
            />
            <s-text-field
              name="description"
              label="Description"
              type="text"
              class="mb-n1"
              filled
              required
              v-model="value.description"
              :error-messages="
                getErrors('text', $v.value.description, 'Description')
              "
              @blur="$v.value.description.$touch()"
            />
            <s-select
              label="Project"
              name="project"
              filled
              required
              :items="projects"
              item-text="name"
              item-value="id"
              v-model="value.project"
              :error-messages="
                getErrors('project', $v.value.project, 'Project')
              "
              @blur="$v.value.project.$touch()"
            >
              <template #prepend-item>
                <v-list-item ripple @click="showCreateProject">
                  <v-list-item-action class="mr-4">
                    <v-icon>mdi-plus</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title> New Project </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="my-2"></v-divider>
              </template>
            </s-select>
            <env-vars
              :envars="envars"
              @showAppEnvVar="showEnvVar"
              @submit="addVar"
              @delete="deleteVar"
            />
            <field-error-message
              :messages="getErrors('envars', $v.value.envars, 'ENV Variables')"
            />
            <div class="d-flex mt-8">
              <s-btn
                class="mr-1"
                :loading="isSubmitting"
                type="submit"
                color="primary"
                elevation="0"
              >
                Submit
              </s-btn>
              <s-btn
                class="delete-btn ml-2"
                elevation="0"
                @click="clearState"
                text
              >
                <v-icon>mdi-close</v-icon>
                Cancel
              </s-btn>
            </div>
          </v-col>
        </v-row>
      </v-form>
      <s-dialog
        v-model="showDisplayModal"
        :title="modalTitle"
        persistent
        isModal
      >
        <app-envvar-form v-if="showModal" :type="type" @submit="addVar" />
        <app-project-form
          v-if="showCreateProjectModal"
          @submit="createProject"
        />
      </s-dialog>
    </v-col>
  </v-row>
</template>
<script>
import getErrors from '@/mixins/vuelidateErrors'
import { required, requiredIf, minLength } from 'vuelidate/lib/validators'
import EnvVars from '@/components/apps/EnvVars'
import FieldErrorMessage from '@/components/ui/FieldErrorMessage'
import AppEnvVarForm from '@/components/apps/AppEnvVarForm'
import AppProjectForm from '@/components/apps/AppProjectForm'
import { mapGetters } from 'vuex'
export default {
  name: 'AppForm',
  mixins: [getErrors],
  components: {
    'env-vars': EnvVars,
    'app-envvar-form': AppEnvVarForm,
    'app-project-form': AppProjectForm,
    'field-error-message': FieldErrorMessage,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      default: 'app',
    },
    showModal: {
      type: Boolean,
      default: false,
    },
    showCreateProjectModal: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      projects: 'app/projects',
    }),
    modalTitle() {
      if (this.showModal) return 'Add Endpoint'
      if (this.showCreateProjectModal) return 'Create Project'

      return 'Modal'
    },
    showDisplayModal() {
      return this.showModal || this.showCreateProjectModal
    },
    envars() {
      return this.value.envars
    },
  },
  data() {
    return {
      isSubmitting: false,
      key: 'envars',
    }
  },
  validations: {
    value: {
      name: {
        required,
        minLength: minLength(2),
      },
      description: {
        required,
      },
      project: {
        required,
      },
      envars: {
        required: requiredIf(function () {
          return this.type === 'app'
        }),
        $each: {
          name: {
            required,
          },
          value: {
            required: requiredIf(function (nestedModel) {
              return (
                nestedModel.existing !== undefined &&
                nestedModel.existing === false
              )
            }),
          },
        },
      },
    },
  },
  methods: {
    clearState() {
      // TODO
      // Add confirmation to discard data provided
      this.closeCreateApp()
    },
    closeCreateApp() {
      this.$router.push({ name: 'apps' })
    },
    save() {
      console.log('45678', this.$v.value.envars, this.value)
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      console.log('1234')
      this.$emit('submit')
    },
    showCreateProject() {
      this.$emit('project')
    },
    createProject(payload) {
      this.$emit('submitProject', payload)
    },
    showEnvVar() {
      this.$emit('show')
    },
    deleteVar({ index, id }) {
      this.value[this.key].splice(index, 1)
      this.$emit('delete', { index, id })
    },
    addVar(envar) {
      this.value[this.key].push({ ...envar, existing: false })
    },
  },
}
</script>
<style scoped>
.count-box {
  background: #dfe2e6;
  border: 1px solid #dfe2e6;
  box-sizing: border-box;
  border-radius: 6px;
  width: 26px;
  height: 23px;
  color: #5599ff;
  font-size: 14px;
}
.bg-white {
  background: white;
}

.delete-btn {
  color: #ec3944;
  background-color: #fff !important;
}
</style>
